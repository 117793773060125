import { create } from 'zustand';

interface Coordinates {
    latitude: number;
    longitude: number;
    ip: string;
}
interface IpLocationState {
    coordinates: Coordinates | null;
    setCoordinates: (coordinates: Coordinates) => void;
}
export const useLocationStore = create<IpLocationState>()((set) => ({
    coordinates: null,
    setCoordinates: (coordinates) => {
        set(() => ({ coordinates }));
    },
}));
