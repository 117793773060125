import { useQuery } from '@tanstack/react-query';

import { fetchGracefully } from '@/app/utils';

interface IPLocationResult {
    ip: string;
    hostname: string;
    city: string;
    region: string;
    country: string;
    loc: string;
    org: string;
    postal: string;
    timezone: string;
}

interface Coordinates {
    latitude: number;
    longitude: number;
    ip: string;
}

export const useFetchIp = () => {
    return useQuery({
        queryKey: ['fetchIp'],
        queryFn: async (): Promise<Coordinates> => {
            const response = await fetch('https://ipinfo.io/json?token=41bf9d7d3f7505');
            const locationResult: IPLocationResult = await response.json();

            const [latitude, longitude] = locationResult.loc.split(',').map(Number) as [number, number];
            return {
                ip: locationResult.ip,
                latitude,
                longitude,
            };
        },
        staleTime: 1000 * 60,
        gcTime: Infinity,
        enabled: true,
    });
};

export const useSpinServer = () =>
    useQuery({
        queryKey: ['spinServer'],
        queryFn: () => fetchGracefully(`/api/spin`),
    });
