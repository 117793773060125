import { Squares2X2Icon } from '@heroicons/react/24/outline';
import { Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { FC, Suspense } from 'react';

import { Ripple } from '@/app/components/Ripple';
import Badge, { TwMainColor } from '@/app/components/shared/Badge';
import ButtonPrimary from '@/app/components/shared/Button/ButtonPrimary';
import ListingImageGallery from '@/app/components/shared/Detail/ListingImageGallery/ListingImageGallery';
import StartRating from '@/app/components/shared/StartRating';
import { getDistanceInM } from '@/app/utils';
import { useGlobalContext } from '@/hooks/useGlobalContext';
import lostBurito from '@/images/lostBurito.webp';
import { amenitiesDemos, includesDemo } from './constant';
import LikeSaveBtns from './LikeSaveBtns';

export const ListingCarDetailPage: FC = () => {
    const thisPathname = usePathname();
    const router = useRouter();
    const handleOpenModalImageGallery = () => {
        router.push(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
    };
    const searchParams = useSearchParams();
    const modal = searchParams.get('modal');

    const handleCloseModalImageGallery = () => {
        const params = new URLSearchParams(document.location.search);
        params.delete('modal');
        router.push(`${thisPathname}/?${params.toString()}`);
    };

    const { state, fetchingError, isResultFetching } = useGlobalContext();

    if (fetchingError) {
        return (
            <Stack direction="column" justifyContent="center" alignItems="center">
                <Typography variant="h2">😿</Typography>
                <Typography variant="caption" color="red" align="center" className="mix-blend-color-burn">
                    Sorry, something wrong happened
                    <br /> Try again later...{' '}
                </Typography>
            </Stack>
        );
    }

    if ((isResultFetching || state.isLocationLoading) && state.winnerPlace == null) {
        return (
            <div className="flex flex-col pb-10 text-center">
                <Ripple />
                <span>
                    {state.isLocationLoading && 'Finding your location...'}
                    {isResultFetching && 'Finding best pick for you...'}
                </span>
            </div>
        );
    }
    if (!state.hasFetched) {
        return null;
    }

    if (state.winnerPlace == null && state.hasFetched && !(isResultFetching || state.isLocationLoading)) {
        return (
            <div className="ml-10 mr-10 flex flex-col items-center justify-center text-center">
                <span className="mb-5">Loco's sad, there's no place like the one you're looking for</span>
                <Image src={lostBurito} alt={'Lost burito'} width={300} className="max-w-1/4 mx-auto rounded pb-20" />
            </div>
        );
    }
    const myAddress = 'Your awesome location'; // TODO: implement google location API if needed

    const { winnerPlace } = state;
    if (winnerPlace == null) {
        return null;
    }

    const navigateTo = (url: string) => {
        window.open(url, '_blank');
    };
    const getParamFromGoogle = (param: string) => {
        if (winnerPlace == null) {
            return null;
        }
        try {
            const googleResponse = JSON.parse(winnerPlace.googleJson);
            return googleResponse?.[param];
        } catch (err) {
            return null;
        }
    };

    const meterDistance =
        state.currentLocation != null
            ? getDistanceInM(
                  {
                      lat: state.currentLocation.coords.latitude,
                      lng: state.currentLocation.coords.longitude,
                  },
                  {
                      lat: winnerPlace.lat,
                      lng: winnerPlace.lng,
                  }
              )
            : null;

    const randomColors: TwMainColor[] = ['pink', 'green', 'yellow', 'red', 'indigo', 'blue', 'purple', 'gray'];
    const getRandomColor = (): TwMainColor | undefined => randomColors[Math.floor(Math.random() * randomColors.length)];
    const walkMinDistance = meterDistance != null ? Math.ceil(meterDistance / 83.33) : null;
    // 1. Create a new Date object.
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + (walkMinDistance ?? 0));

    const arrivalTime = `~ ${currentTime.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
    const renderSection1 = () => (
        <div className="listingSection__wrap !space-y-6">
            {/* 1 */}
            <div className="flex items-center justify-between p-4">
                <h2 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">{winnerPlace.name}</h2>
                <LikeSaveBtns />
            </div>

            {/* 2 */}

            {/* 3 */}
            <div className="flex items-center space-x-4 pl-4 pr-4">
                <StartRating point={winnerPlace.rating ?? 5} />
                <span>·</span>
                <span>
                    <i className="las la-map-marker-alt" />
                    <span className="ml-1"> {winnerPlace.address}</span>
                </span>
            </div>

            {/* 4 */}
            {/* <div className="flex items-center"> */}
            {/* TODO: iconu toho místa */}
            {/* <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" /> */}
            {/* <span className="ml-2.5 text-neutral-500 dark:text-neutral-400"> */}
            {/*    Car owner */}
            {/*    {' '} */}
            {/*    <span className="font-medium text-neutral-900 dark:text-neutral-200">Kevin Francis</span> */}
            {/* </span> */}
            {/* </div> */}
            <div className="flex items-center justify-between pl-4 pr-4">
                <div>
                    {winnerPlace.types.map((type) => (
                        <Badge color={getRandomColor()} key={type} name={type} className="mr-2" />
                    ))}
                </div>
            </div>

            {/* 5 */}
            <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

            {/* 6 */}
            <div className="flex items-center space-x-8 pl-4 pr-4 text-sm text-neutral-700 xl:justify-start xl:space-x-12 dark:text-neutral-300">
                <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-x-3 sm:space-y-0 sm:text-left">
                    <i className="las la-walking text-2xl" />
                    <span className="">{meterDistance} m</span>
                </div>
                <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-x-3 sm:space-y-0 sm:text-left">
                    <i className="las la-clock text-2xl" />
                    <span className="">{walkMinDistance} min</span>
                </div>
                {/* <div className="flex flex-col items-center space-y-3 text-center sm:flex-row sm:space-x-3 sm:space-y-0 sm:text-left "> */}
                {/*    <i className="las la-suitcase text-2xl" /> */}
                {/*    <span className=""> 2 bags</span> */}
                {/* </div> */}
            </div>
        </div>
    );

    //
    const renderSectionTienIch = () => (
        <div className="listingSection__wrap">
            <div>
                <h2 className="text-2xl font-semibold">Vehicle parameters & utilities </h2>
                <span className="mt-2 block text-neutral-500 dark:text-neutral-400">
                    Questions are at the heart of making things great.
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            {/* 6 */}
            <div className="grid grid-cols-1 gap-x-10 gap-y-6 text-sm text-neutral-700 lg:grid-cols-2 dark:text-neutral-300">
                {/* TIEN ICH 1 */}
                {amenitiesDemos.map((item) => (
                    <div key={item.name} className="flex items-center space-x-4">
                        <div className="w-10 flex-shrink-0">
                            <Image width="1280" height="980" src={item.icon} alt={item.name} />
                        </div>
                        <span>{item.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderSection2 = () => (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">Car descriptions</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            <div className="text-neutral-6000 dark:text-neutral-300">
                <p>
                    Until the all-new TUCSON hits the dealer showrooms you can check it out in our Showroom Walkaround
                    video. Watch the video and join our product specialist as he gives you an up-close look of our
                    latest SUV
                    <br />
                    <br />
                    Questions are at the heart of making things great. Watch our celebrity-filled TV ad and you’ll see
                    that when we say “everything,” we mean everything.
                </p>
            </div>
        </div>
    );

    const renderSection3 = () => (
        <div className="listingSection__wrap">
            <div>
                <h2 className="text-2xl font-semibold">Include </h2>
                <span className="mt-2 block text-neutral-500 dark:text-neutral-400">Included in the price</span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
            {/* 6 */}
            <div className="grid grid-cols-1 gap-6 text-sm text-neutral-700 lg:grid-cols-2 dark:text-neutral-300">
                {includesDemo
                    .filter((_, i) => i < 12)
                    .map((item) => (
                        <div key={item.name} className="flex items-center space-x-3">
                            <i className="las la-check-circle text-2xl" />
                            <span>{item.name}</span>
                        </div>
                    ))}
            </div>
            F
        </div>
    );

    const renderSection7 = () => (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <div>
                <h2 className="text-2xl font-semibold">Location</h2>
                <span className="mt-2 block text-neutral-500 dark:text-neutral-400">
                    San Diego, CA, United States of America (SAN-San Diego Intl.)
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* MAP */}
            <div className="aspect-h-5 aspect-w-5 z-0 rounded-xl ring-1 ring-black/10 sm:aspect-h-3">
                <div className="z-0 overflow-hidden rounded-xl">
                    MAP WILL BE HERE
                    {/* <iframe */}
                    {/*    title="x" */}
                    {/*    width="100%" */}
                    {/*    height="100%" */}
                    {/*    loading="lazy" */}
                    {/*    allowFullScreen */}
                    {/*    referrerPolicy="no-referrer-when-downgrade" */}
                    {/*    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France" */}
                    {/* /> */}
                </div>
            </div>
        </div>
    );

    const renderSection8 = () => (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <h2 className="text-2xl font-semibold">Things to know</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* CONTENT */}
            <div>
                <h4 className="text-lg font-semibold">Cancellation policy</h4>
                <span className="mt-3 block text-neutral-500 dark:text-neutral-400">
                    Lock in this fantastic price today, cancel free of charge anytime. Reserve now and pay at pick-up.
                </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

            {/* CONTENT */}
            <div>
                <h4 className="text-lg font-semibold">Special Note</h4>
                <span className="mt-3 block text-neutral-500 dark:text-neutral-400">
                    We asked ourselves, “How can we make the dash not only look better, but also give the driver a
                    better look outside?” The unexpected answer is having no hood above the available 10.25-inch digital
                    instrument cluster...
                </span>
            </div>
        </div>
    );

    const renderSidebarDetail = () => (
        <div className="listingSection__wrap p-4 lg:shadow-xl">
            <div className="flex flex-row place-content-between items-center pl-4 pr-4">
                <span className="block text-2xl font-semibold">Road plan</span>
                {/* <i className="las la-suitcase text-2xl" /> */}
                <ButtonPrimary
                    className="align-middle"
                    onClick={() => {
                        navigateTo(getParamFromGoogle('url'));
                    }}
                >
                    Navigate <i className="las la-arrow-right ml-2 text-xl" />
                </ButtonPrimary>
            </div>
            <div className="mt-8 flex pl-4 pr-4">
                <div className="flex flex-shrink-0 flex-col items-center py-2">
                    <span className="block h-6 w-6 rounded-full border border-neutral-400" />
                    <span className="my-1 block flex-grow border-l border-dashed border-neutral-400" />
                    <span className="block h-6 w-6 rounded-full border border-neutral-400 bg-green-100" />
                </div>
                <div className="ml-4 space-y-14 text-sm">
                    <div className="flex flex-col space-y-2">
                        <span className="text-neutral-500 dark:text-neutral-400">Now</span>
                        <span className="font-semibold">{myAddress}</span>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <span className="text-neutral-500 dark:text-neutral-400">{arrivalTime}</span>
                        <span className="font-semibold">
                            <span className="text-xl">{winnerPlace.name}</span>
                            <br />
                            {winnerPlace.address}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );

    const stupidFormatImages =
        winnerPlace.imageUrls != null ? winnerPlace.imageUrls.map((url, index) => ({ url, id: index })) : [];
    return (
        <div className="ListingDetailPage">
            {/* Galeire */}

            {stupidFormatImages.length > 1 ? (
                <Suspense>
                    <ListingImageGallery
                        isShowModal={modal === 'PHOTO_TOUR_SCROLLABLE'}
                        onClose={handleCloseModalImageGallery}
                        images={stupidFormatImages}
                    />
                </Suspense>
            ) : null}

            <div className="ListingDetailPage__content container">
                <div className={'nc-ListingCarDetailPage'}>
                    {/* SINGLE HEADER */}
                    <header className="rounded-md sm:rounded-xl">
                        <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
                            {stupidFormatImages[0] != null ? (
                                <div
                                    className="relative col-span-2 row-span-2 cursor-pointer overflow-hidden rounded-md sm:rounded-xl"
                                    onClick={handleOpenModalImageGallery}
                                >
                                    <Image
                                        width="1280"
                                        height="980"
                                        src={stupidFormatImages[0].url}
                                        alt="0"
                                        className="absolute inset-0 h-full w-full rounded-md object-cover sm:rounded-xl"
                                        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                                    />
                                    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity hover:opacity-100" />
                                </div>
                            ) : null}

                            {/*  */}
                            {stupidFormatImages[1] != null ? (
                                <div
                                    className="relative col-span-1 row-span-2 cursor-pointer overflow-hidden rounded-md sm:rounded-xl"
                                    onClick={handleOpenModalImageGallery}
                                >
                                    <Image
                                        width="1280"
                                        height="980"
                                        className="absolute inset-0 h-full w-full rounded-md object-cover sm:rounded-xl"
                                        src={stupidFormatImages[1].url}
                                        alt="1"
                                        sizes="400px"
                                    />
                                    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity hover:opacity-100" />
                                </div>
                            ) : null}

                            {stupidFormatImages
                                .filter((_, i) => i >= 2 && i < 4)
                                .map(({ url: item }, index) => (
                                    <div
                                        key={item}
                                        className={`relative overflow-hidden rounded-md sm:rounded-xl ${
                                            index >= 2 ? 'block' : ''
                                        }`}
                                    >
                                        <div className="aspect-h-3 aspect-w-4">
                                            <Image
                                                width="1280"
                                                height="980"
                                                className="absolute inset-0 h-full w-full rounded-md object-cover sm:rounded-xl"
                                                src={item || ''}
                                                alt="photos"
                                                sizes="400px"
                                            />
                                        </div>

                                        {/* OVERLAY */}
                                        <div
                                            className="absolute inset-0 cursor-pointer bg-neutral-900 bg-opacity-20 opacity-0 transition-opacity hover:opacity-100"
                                            onClick={handleOpenModalImageGallery}
                                        />
                                    </div>
                                ))}

                            <div
                                className="absolute bottom-3 left-3 z-10 hidden cursor-pointer rounded-xl bg-neutral-100 px-4 py-2 text-neutral-500 hover:bg-neutral-200 md:flex md:items-center md:justify-center"
                                onClick={handleOpenModalImageGallery}
                            >
                                <Squares2X2Icon className="h-5 w-5" />

                                <span className="ml-2 text-sm font-medium text-neutral-800">Show all photos</span>
                            </div>
                        </div>
                    </header>

                    {/* MAIn */}
                    <main className="relative z-10 mt-11 flex flex-col pb-20 lg:flex-row">
                        {/* CONTENT */}
                        <div className="w-full space-y-8 lg:w-3/5 lg:space-y-10 lg:pr-10 xl:w-2/3">
                            {renderSection1()}
                            {/* <div className="block lg:hidden">{renderSidebarDetail()}</div> */}
                            {/* {renderSectionTienIch()} */}
                            {/* {renderSection2()} */}
                            {/* {renderSection3()} */}
                            {/* {renderSection7()} */}
                            {/* {renderSection8()}  */}
                        </div>

                        {/* SIDEBAR */}
                        <div className="mt-14 block flex-grow lg:mt-0">{renderSidebarDetail()}</div>
                    </main>
                </div>
            </div>

            {/* OTHER SECTION */}

            {/* STICKY FOOTER MOBILE */}
            {/* <MobileFooterSticky /> */}
        </div>
    );
};
