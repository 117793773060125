'use client';

import { ApolloError } from '@apollo/client';
import React, { createContext, FunctionComponent, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import Smartlook from 'smartlook-client';

import { FilterData } from '@/app/components/shared/FlightSearchForm/FlightSearchForm';
import { SpotPlaceEntity, useGetWinnerPlaceNearbyLazyQuery } from '@/graphql-tools';

interface ApplicationState {
    isLocationLoading: boolean;
    hasLocationError: boolean;
    currentLocation: GeolocationPosition | null;
    winnerPlace: SpotPlaceEntity | null;
    hasFetched: boolean;
    ipLocation: {
        latitude: number;
        longitude: number;
    } | null;
    hasCustomLocation: boolean;
}

export const initialState: ApplicationState = {
    isLocationLoading: false,
    currentLocation: null,
    hasLocationError: false,
    winnerPlace: null,
    hasFetched: false,
    ipLocation: null,
    hasCustomLocation: false,
};

interface ActionType {
    type: string;
    payload: any;
}
interface Actions {
    onPrimaryButtonClick: () => void;
    setSelectedProperty: (value: string) => void;
    setSelectedDistance: (value: number) => void;
    setRating: (value: number) => void;
}

interface GlobalContextType {
    state: ApplicationState;
    dispatch: React.Dispatch<ActionType>;
    isResultFetching: boolean;
    selectedProperty: string;
    selectedDistance: number;
    actions: Actions;
    fetchingError: ApolloError | undefined;
}
export const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const reducer = (state: typeof initialState, { type, payload }: ActionType) => {
    switch (type) {
        case 'setLocationLoading':
            return { ...state, isLocationLoading: payload };
        case 'setLocationError':
            return { ...state, hasLocationError: payload };
        case 'setCurrentLocation':
            return { ...state, currentLocation: payload };
        case 'setWinnerPlace':
            return { ...state, hasFetched: true, winnerPlace: payload };
        case 'setHasFetched':
            return { ...state, hasFetched: true };
        case 'resetResult':
            return { ...state, winnerPlace: null };
        case 'setIpLocation':
            return { ...state, ipLocation: payload };
        case 'setCustomLocation':
            return { ...state, ipLocation: payload, hasCustomLocation: payload };
        default:
            throw new Error();
    }
};

interface FooProps {
    children: React.ReactNode;
}

export const WithProviders: FunctionComponent<FooProps> = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    useEffect(() => {
        if (window.location.hostname !== 'localhost') {
            Smartlook.init('09dc58450064a51349939466038b97271ef648a6');
        }
    }, []);

    const [selectedProperty, setSelectedProperty] = useState<string>('restaurant');
    const [selectedDistance, setSelectedDistance] = useState<number>(1000);
    const [selectedRating, setRating] = useState<number>(4);

    const gatherLocation = useCallback(
        () =>
            new Promise<GeolocationPosition>((resolve) => {
                if (state.currentLocation != null) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    resolve(state.currentLocation);
                }

                if ('geolocation' in navigator) {
                    dispatch({ type: 'setLocationLoading', payload: true });
                    // setLocationLoading(true);

                    navigator.geolocation.getCurrentPosition(
                        (location) => {
                            dispatch({ type: 'setLocationLoading', payload: false });
                            // setLocationLoading(false);
                            // setCurrentLocation(location);
                            dispatch({ type: 'setCurrentLocation', payload: location });
                            // setLocationError(false);
                            dispatch({
                                type: 'setCustomLocation',
                                payload: false,
                            });
                            dispatch({ type: 'setLocationError', payload: false });
                            resolve(location);
                        },
                        () => {
                            // setLocationLoading(false);
                            dispatch({ type: 'setLocationLoading', payload: false });
                            dispatch({ type: 'setLocationError', payload: true });
                            // setLocationError(true);
                            // LOG that there is error
                            // throw geolocationPositionError;
                        }
                    );
                }
            }),
        [state.currentLocation]
    );
    // const [winnerPlace, setWinnerPlace] = useState<SpotPlaceEntity | null>(null);
    const [fetchNearbyPlaces, { loading, data, error }] = useGetWinnerPlaceNearbyLazyQuery({ fetchPolicy: 'no-cache' });

    useEffect(() => {
        if (data?.getWinnerPlaceNearby != null) {
            dispatch({ type: 'setWinnerPlace', payload: data.getWinnerPlaceNearby });
        }
    }, [data]);

    const contextValue = useMemo(() => {
        const fetchWinner = ({
            selectedRating: SR,
            selectedDistance: SD,
            selectedProperty: SP,
            coordinates,
        }: FilterData) =>
            fetchNearbyPlaces({
                variables: {
                    lng: coordinates.coords.longitude,
                    lat: coordinates.coords.latitude,
                    radius: SD,
                    placeType: SP,
                    rating: SR,
                },
            });
        const onPrimaryButtonClick = async () => {
            dispatch({ type: 'resetResult', payload: null });
            const primaryOptions = {
                selectedDistance,
                selectedProperty,
                selectedRating,
            };
            if (state.currentLocation == null) {
                void gatherLocation().then((coordinates) => {
                    void fetchWinner({ ...primaryOptions, coordinates });
                    dispatch({ type: 'setHasFetched', payload: null });
                });
            } else {
                dispatch({ type: 'setLocationError', payload: false });
                await fetchWinner({ ...primaryOptions, coordinates: state.currentLocation });
                dispatch({ type: 'setHasFetched', payload: null });
            }
        };

        return {
            state,
            isResultFetching: loading,
            fetchingError: error,
            selectedProperty,
            selectedDistance,
            dispatch,
            actions: { onPrimaryButtonClick, setSelectedProperty, setSelectedDistance, setRating },
        };
    }, [state, loading, error, selectedProperty, selectedDistance, fetchNearbyPlaces, selectedRating, gatherLocation]);
    return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};
