import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useRef, useState } from 'react';

import { ListingGalleryImage } from '@/app/components/shared/Detail/constant';
import { getNewParam } from '../ListingImageGallery';
import SharedModal from './SharedModal';

export default function Modal({ images, onClose }: { images: ListingGalleryImage[]; onClose?: () => void }) {
    const overlayRef = useRef<HTMLDivElement>(null);
    const router = useRouter();
    const searchParams = useSearchParams();
    const photoId = searchParams.get('photoId');
    const navigate = router.push;
    const thisPathname = usePathname();

    const index = Number(photoId);

    const [direction, setDirection] = useState(0);
    const [curIndex, setCurIndex] = useState(index);

    function handleClose() {
        if (onClose != null) {
            onClose();
        }
    }

    function changePhotoId(newVal: number) {
        if (newVal > index) {
            setDirection(1);
        } else {
            setDirection(-1);
        }
        setCurIndex(newVal);
        navigate(`${thisPathname}/?${getNewParam({ value: newVal })}`);
    }
    //
    // useKeypress('ArrowRight', () => {
    //     if (index + 1 < images.length) {
    //         changePhotoId(index + 1);
    //     }
    // });
    //
    // useKeypress('ArrowLeft', () => {
    //     if (index > 0) {
    //         changePhotoId(index - 1);
    //     }
    // });

    return (
        <>
            <Dialog
                static
                open
                onClose={handleClose}
                initialFocus={overlayRef}
                className="fixed inset-0 z-50 flex items-center justify-center"
            >
                {/* <Dialog.Overlay */}
                {/*    ref={overlayRef} */}
                {/*    as={motion.div} */}
                {/*    key="backdrop" */}
                {/*    className="fixed inset-0 z-30 bg-black" */}
                {/*    initial={{ opacity: 0 }} */}
                {/*    animate={{ opacity: 1 }} */}
                {/* /> */}
                <SharedModal
                    index={curIndex}
                    direction={direction}
                    images={images}
                    changePhotoId={changePhotoId}
                    closeModal={handleClose}
                    navigation
                />
            </Dialog>
        </>
    );
}
