import Image from 'next/image';
import React, { FC, useState } from 'react';

import { useGlobalContext } from '@/hooks/useGlobalContext';
import { HeroSearchForm } from './shared/HeroSearchForm';

interface SectionHeroProps {
    image: {
        url: string;
        city: string;
    } | null;
}

export const SectionHero: FC<SectionHeroProps> = ({ image }) => {
    const context = useGlobalContext();
    const hasResult = context.state.winnerPlace != null;
    const [loaded, setLoaded] = useState(false);

    return (
        <div className="nc-SectionHero relative flex flex-col pt-10 lg:flex-col lg:pb-16" data-nc-id="SectionHero">
            {!hasResult ? (
                <div className="flex flex-col lg:flex-row lg:items-center">
                    <div className="flex flex-shrink-0 flex-col items-start space-y-8 sm:space-y-10 md:pb-14 lg:mr-10 lg:w-1/2 lg:pb-64 xl:mr-0 xl:pr-14">
                        <h2 className="text-4xl font-medium !leading-[114%] md:text-5xl xl:text-7xl">
                            Find your next place to go!
                        </h2>
                        <span className="text-base text-neutral-500 md:text-lg dark:text-neutral-400">
                            Get ready to explore with Loco Locate! Get ready for unique journeys that match what you
                            love. An incredible experience is just around the corner.
                        </span>
                    </div>

                    <div className={`hidden place-items-center lg:block ${loaded ? 'animate-fadein' : 'opacity-0'}`}>
                        {image != null ? (
                            <Image
                                // backdrop-blur-sm opacity-5 grayscale
                                className="max-h-[480px] rounded-lg transition-opacity duration-1000"
                                src={image.url}
                                alt="hero"
                                width={640}
                                height={480}
                                onLoad={() => {
                                    setLoaded(true);
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            ) : null}
            <div className={`z-10 w-full md:mb-12 lg:mb-0 lg:block ${!hasResult ? 'lg:-mt-40' : ''}`}>
                <HeroSearchForm />
            </div>
        </div>
    );
};
