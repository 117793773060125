import React, { useState } from 'react';
import { Map, Marker, ViewStateChangeEvent } from 'react-map-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

import Pin from '@/app/components/LocationMap/Pin';
import { useGlobalContext } from '@/hooks/useGlobalContext';

interface MapBoxMapProps {
    longitude: number;
    latitude: number;
    className?: string;
}
export function MapBoxMap({ latitude, longitude, className }: MapBoxMapProps) {
    const { dispatch } = useGlobalContext();
    const [marker, setMarker] = useState({
        latitude,
        longitude,
    });

    const setLocation = ({ viewState }: ViewStateChangeEvent) => {
        dispatch({
            type: 'setCurrentLocation',
            payload: {
                coords: {
                    latitude: viewState.latitude,
                    longitude: viewState.longitude,
                },
            },
        });
        dispatch({
            type: 'setCustomLocation',
            payload: true,
        });
    };

    return (
        <div className={`animate-fadein ${className != null ? className : ''}`}>
            <Map
                mapboxAccessToken="pk.eyJ1IjoicHJvZ3Jlc2FrIiwiYSI6ImNsbHdxbmZyMDBhejQzY3N5eG94dWl5aHEifQ.RHXtRlRZubq4kLccYHS4Hg"
                initialViewState={{
                    longitude,
                    latitude,
                    zoom: 14,
                }}
                onMove={({ viewState }) => {
                    setMarker({
                        longitude: viewState.longitude,
                        latitude: viewState.latitude,
                    });
                }}
                onZoomEnd={setLocation}
                onDragEnd={setLocation}
                optimizeForTerrain={false}
                style={{ width: '100%', height: 400 }}
                mapStyle="mapbox://styles/mapbox/streets-v9"
            >
                <Marker longitude={marker.longitude} latitude={marker.latitude} anchor="bottom">
                    <Pin size={200} />
                </Marker>
            </Map>
        </div>
    );
}
