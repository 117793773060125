import React, { Suspense, useEffect } from 'react';

import { SectionHero } from '@/app/components/SectionHero';
import { BgGlassmorphism } from '@/app/components/shared/BgGlassmorphism';
import { ListingCarDetailPage } from '@/app/components/shared/Detail/ListingCarDetailPage';
import { useGetLocationImagesLazyQuery } from '@/graphql-tools';
import { useFetchIp, useSpinServer } from '@/hooks/useFetchIp';
import { useLocationStore } from '@/store/useStore';

export function MainPage() {
    const setCoordinates = useLocationStore((state) => state.setCoordinates);
    const [fetchBg, { data }] = useGetLocationImagesLazyQuery();

    useSpinServer();

    const { data: coordinates } = useFetchIp();

    useEffect(() => {
        if (coordinates != null) {
            setCoordinates(coordinates);
            if (data == null) {
                void fetchBg({ variables: { ipaddress: coordinates.ip } });
            }
        }
    }, [coordinates, data, fetchBg, setCoordinates]);

    const locationImage = data?.getLocationImages?.[0];
    const bgImage =
        locationImage?.city != null && locationImage.url != null
            ? {
                  url: locationImage.url,
                  city: locationImage.city,
              }
            : null;

    return (
        <div className="nc-PageHome relative">
            {bgImage != null ? <BgGlassmorphism image={bgImage} /> : null}
            <div className="m container relative space-y-24 lg:space-y-28">
                <SectionHero image={bgImage} />
            </div>
            <Suspense>
                <ListingCarDetailPage />
            </Suspense>
        </div>
    );
}
