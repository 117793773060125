import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends Record<string, unknown>> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends Record<string, unknown>, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
}

export interface BgImageEntity {
    __typename?: 'BgImageEntity';
    city?: Maybe<Scalars['String']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    externalId?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    url?: Maybe<Scalars['String']['output']>;
}

export interface Query {
    __typename?: 'Query';
    getLocationImages?: Maybe<Array<BgImageEntity>>;
    getWinnerPlaceNearby?: Maybe<SpotPlaceEntity>;
}

export interface QueryGetLocationImagesArgs {
    ipaddress: Scalars['String']['input'];
}

export interface QueryGetWinnerPlaceNearbyArgs {
    lat: Scalars['Float']['input'];
    lng: Scalars['Float']['input'];
    placeType: Scalars['String']['input'];
    radius: Scalars['Float']['input'];
    rating: Scalars['Float']['input'];
}

export interface SpotPlaceEntity {
    __typename?: 'SpotPlaceEntity';
    address?: Maybe<Scalars['String']['output']>;
    externalId: Scalars['String']['output'];
    googleJson: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    imageUrls: Array<Scalars['String']['output']>;
    lat: Scalars['Float']['output'];
    lng: Scalars['Float']['output'];
    name: Scalars['String']['output'];
    rating?: Maybe<Scalars['Float']['output']>;
    types: Array<Scalars['String']['output']>;
}

export type GetLocationImagesQueryVariables = Exact<{
    ipaddress: Scalars['String']['input'];
}>;

export interface GetLocationImagesQuery {
    __typename?: 'Query';
    getLocationImages?: Array<{
        __typename?: 'BgImageEntity';
        id?: string | null;
        description?: string | null;
        url?: string | null;
        externalId?: string | null;
        city?: string | null;
    }> | null;
}

export type GetWinnerPlaceNearbyQueryVariables = Exact<{
    lat: Scalars['Float']['input'];
    lng: Scalars['Float']['input'];
    radius: Scalars['Float']['input'];
    placeType: Scalars['String']['input'];
    rating: Scalars['Float']['input'];
}>;

export interface GetWinnerPlaceNearbyQuery {
    __typename?: 'Query';
    getWinnerPlaceNearby?: {
        __typename?: 'SpotPlaceEntity';
        id: string;
        name: string;
        imageUrls: Array<string>;
        externalId: string;
        address?: string | null;
        lng: number;
        lat: number;
        googleJson: string;
        types: Array<string>;
        rating?: number | null;
    } | null;
}

export const GetLocationImagesDocument = gql`
    query getLocationImages($ipaddress: String!) {
        getLocationImages(ipaddress: $ipaddress) {
            id
            description
            url
            externalId
            city
        }
    }
`;

/**
 * __useGetLocationImagesQuery__
 *
 * To run a query within a React component, call `useGetLocationImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationImagesQuery({
 *   variables: {
 *      ipaddress: // value for 'ipaddress'
 *   },
 * });
 */
export function useGetLocationImagesQuery(
    baseOptions: Apollo.QueryHookOptions<GetLocationImagesQuery, GetLocationImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetLocationImagesQuery, GetLocationImagesQueryVariables>(GetLocationImagesDocument, options);
}
export function useGetLocationImagesLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetLocationImagesQuery, GetLocationImagesQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetLocationImagesQuery, GetLocationImagesQueryVariables>(
        GetLocationImagesDocument,
        options
    );
}
export type GetLocationImagesQueryHookResult = ReturnType<typeof useGetLocationImagesQuery>;
export type GetLocationImagesLazyQueryHookResult = ReturnType<typeof useGetLocationImagesLazyQuery>;
export type GetLocationImagesQueryResult = Apollo.QueryResult<GetLocationImagesQuery, GetLocationImagesQueryVariables>;
export const GetWinnerPlaceNearbyDocument = gql`
    query getWinnerPlaceNearby($lat: Float!, $lng: Float!, $radius: Float!, $placeType: String!, $rating: Float!) {
        getWinnerPlaceNearby(lat: $lat, lng: $lng, radius: $radius, placeType: $placeType, rating: $rating) {
            id
            name
            imageUrls
            externalId
            address
            lng
            lat
            googleJson
            types
            rating
        }
    }
`;

/**
 * __useGetWinnerPlaceNearbyQuery__
 *
 * To run a query within a React component, call `useGetWinnerPlaceNearbyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWinnerPlaceNearbyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWinnerPlaceNearbyQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      radius: // value for 'radius'
 *      placeType: // value for 'placeType'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useGetWinnerPlaceNearbyQuery(
    baseOptions: Apollo.QueryHookOptions<GetWinnerPlaceNearbyQuery, GetWinnerPlaceNearbyQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetWinnerPlaceNearbyQuery, GetWinnerPlaceNearbyQueryVariables>(
        GetWinnerPlaceNearbyDocument,
        options
    );
}
export function useGetWinnerPlaceNearbyLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetWinnerPlaceNearbyQuery, GetWinnerPlaceNearbyQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetWinnerPlaceNearbyQuery, GetWinnerPlaceNearbyQueryVariables>(
        GetWinnerPlaceNearbyDocument,
        options
    );
}
export type GetWinnerPlaceNearbyQueryHookResult = ReturnType<typeof useGetWinnerPlaceNearbyQuery>;
export type GetWinnerPlaceNearbyLazyQueryHookResult = ReturnType<typeof useGetWinnerPlaceNearbyLazyQuery>;
export type GetWinnerPlaceNearbyQueryResult = Apollo.QueryResult<
    GetWinnerPlaceNearbyQuery,
    GetWinnerPlaceNearbyQueryVariables
>;
