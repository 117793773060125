import { useContext } from 'react';

import { GlobalContext } from '@/app/components/WithProviders';

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (context === undefined) {
        throw new Error('useGlobalContext must be used within a GlobalContext');
    }
    return context;
};
