import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import React, { FC, useEffect, useState } from 'react';

export interface NcInputNumberProps {
    className?: string;
    defaultValue?: number;
    min?: number;
    max?: number;
    onChange?: (value: number) => void;
    label?: string;
    desc?: string;
}

const NcInputNumber: FC<NcInputNumberProps> = ({
    className = 'w-full',
    defaultValue = 0,
    min = 0,
    max,
    onChange,
    label,
    desc,
}) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleClickDecrement = () => {
        if (min >= value) return;
        setValue((state) => state - 1);
        if (onChange != null) {
            onChange(value - 1);
        }
    };
    const handleClickIncrement = () => {
        if (max && max <= value) return;
        setValue((state) => state + 1);
        if (onChange != null) {
            onChange(value + 1);
        }
    };

    const renderLabel = () => (
        <div className="flex flex-col">
            <span className="font-medium text-neutral-800 dark:text-neutral-200">{label}</span>
            {desc && <span className="text-xs font-normal text-neutral-500 dark:text-neutral-400">{desc}</span>}
        </div>
    );

    return (
        <div
            className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}
            data-nc-id="NcInputNumber"
        >
            {label && renderLabel()}

            <div className="nc-NcInputNumber flex w-28 items-center justify-between">
                <button
                    className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-400 bg-white hover:border-neutral-700 focus:outline-none disabled:cursor-default disabled:opacity-50 disabled:hover:border-neutral-400 dark:border-neutral-500 dark:bg-neutral-900 dark:disabled:hover:border-neutral-500"
                    type="button"
                    onClick={handleClickDecrement}
                    disabled={min >= value}
                    aria-label="Decrement value"
                >
                    <MinusIcon className="h-4 w-4" />
                </button>
                <span>{value}</span>
                <button
                    className="flex h-8 w-8 items-center justify-center rounded-full border border-neutral-400 bg-white hover:border-neutral-700 focus:outline-none disabled:cursor-default disabled:opacity-50 disabled:hover:border-neutral-400 dark:border-neutral-500 dark:bg-neutral-900 dark:disabled:hover:border-neutral-500"
                    type="button"
                    onClick={handleClickIncrement}
                    disabled={max ? max <= value : false}
                    aria-label="Increment value"
                >
                    <PlusIcon className="h-4 w-4" />
                </button>
            </div>
        </div>
    );
};

export default NcInputNumber;
