import * as React from 'react';

function Pin(props: { size: number }) {
    const { size = 20 } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            id="radius"
            data-name="Line Color"
            className="icon line-color"
        >
            <line
                id="secondary-upstroke"
                x1="12.05"
                y1="12"
                x2="11.95"
                y2="12"
                // conver to style object
                // style="fill: none; stroke: rgb(44, 169, 188); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"
                style={{
                    fill: 'none',
                    stroke: 'rgb(44, 169, 188)',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 1.5,
                }}
            />

            <circle
                id="primary"
                cx="12"
                cy="12"
                r="9"
                style={{
                    fill: 'none',
                    stroke: 'rgb(0, 0, 0)',
                    strokeLinecap: 'round',
                    strokeLinejoin: 'round',
                    strokeWidth: 0.4,
                }}
            />
        </svg>
    );
}

export default React.memo(Pin);
