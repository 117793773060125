import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import {
    CoffeeOutlined,
    FavoriteBorderOutlined,
    FitnessCenterOutlined,
    LocalBarOutlined,
    LunchDiningOutlined,
    ParkOutlined,
    SelfImprovementOutlined,
    SvgIconComponent,
} from '@mui/icons-material';
import React, { FC, Fragment, useState } from 'react';

import ButtonSubmit from '@/app/components/shared/ButtonSubmit';
import NcInputNumber from '@/app/components/shared/NcInputNumber';
import { useGlobalContext } from '@/hooks/useGlobalContext';
import FlightDateRangeInput from './FlightDateRangeInput';

export interface FilterData {
    coordinates: GeolocationPosition;
    selectedDistance: number;
    selectedRating: number;
    selectedProperty: string;
}

interface HeadingFilterProps {}

const flightClass = [
    {
        name: 'Economy',
        href: '##',
    },
    {
        name: 'Business',
        href: '##',
    },
    {
        name: 'Multiple',
        href: '##',
    },
];

export type TypeDropOffLocationType = 'roundTrip' | 'oneWay' | '';

export const FlightSearchForm: FC<HeadingFilterProps> = () => {
    const context = useGlobalContext();
    const { selectedProperty } = context;
    const { setSelectedProperty } = context.actions;
    // const [dropOffLocationType, setDropOffLocationType] = useState<PlaceType>('restaurant');
    const [flightClassState, setFlightClassState] = useState('Economy');

    const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
    const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
    const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);

    const { state } = useGlobalContext();

    const handleChangeData = (value: number, type: 'guestAdults' | 'guestChildren' | 'guestInfants') => {
        const newValue = {
            guestAdults: guestAdultsInputValue,
            guestChildren: guestChildrenInputValue,
            guestInfants: guestInfantsInputValue,
        };
        if (type === 'guestAdults') {
            setGuestAdultsInputValue(value);
            newValue.guestAdults = value;
        }
        if (type === 'guestChildren') {
            setGuestChildrenInputValue(value);
            newValue.guestChildren = value;
        }
        if (type === 'guestInfants') {
            setGuestInfantsInputValue(value);
            newValue.guestInfants = value;
        }
    };

    const totalGuests = guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

    const renderGuest = () => (
        <Popover className="relative">
            {({ open }) => (
                <>
                    <Popover.Button
                        as="button"
                        className={` ${open ? '' : ''} inline-flex items-center rounded-md px-4 py-1.5 text-xs font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                        onClickCapture={() => document.querySelector('html')?.click()}
                    >
                        <span>{`${totalGuests || ''} Guests`}</span>
                        <ChevronDownIcon
                            className={`${
                                open ? '' : 'text-opacity-70'
                            } ml-2 h-4 w-4 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                            aria-hidden="true"
                        />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-1/2 top-full z-20 mt-3 w-full max-w-sm -translate-x-1/2 rounded-3xl bg-white px-4 py-5 shadow-xl ring-1 ring-black/5 sm:min-w-[340px] sm:px-8 sm:py-6 dark:bg-neutral-800 dark:ring-white/10">
                            <NcInputNumber
                                className="w-full"
                                defaultValue={guestAdultsInputValue}
                                onChange={(value) => {
                                    handleChangeData(value, 'guestAdults');
                                }}
                                max={10}
                                min={1}
                                label="Adults"
                                desc="Ages 13 or above"
                            />
                            <NcInputNumber
                                className="mt-6 w-full"
                                defaultValue={guestChildrenInputValue}
                                onChange={(value) => {
                                    handleChangeData(value, 'guestChildren');
                                }}
                                max={4}
                                label="Children"
                                desc="Ages 2–12"
                            />

                            <NcInputNumber
                                className="mt-6 w-full"
                                defaultValue={guestInfantsInputValue}
                                onChange={(value) => {
                                    handleChangeData(value, 'guestInfants');
                                }}
                                max={4}
                                label="Infants"
                                desc="Ages 0–2"
                            />
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );

    const renderSelectClass = () => (
        <Popover className="relative">
            {({ open, close }) => (
                <>
                    <Popover.Button
                        className={` ${open ? '' : ''} inline-flex items-center rounded-md px-4 py-1.5 text-xs font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                        onClickCapture={() => document.querySelector('html')?.click()}
                    >
                        <span>{flightClassState}</span>
                        <ChevronDownIcon
                            className={`${
                                open ? '' : 'text-opacity-70'
                            } ml-2 h-4 w-4 transition duration-150 ease-in-out group-hover:text-opacity-80`}
                            aria-hidden="true"
                        />
                    </Popover.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                    >
                        <Popover.Panel className="absolute left-1/2 top-full z-20 mt-3 w-screen max-w-[200px] -translate-x-1/2 transform px-4 sm:max-w-[220px] sm:px-0">
                            <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                                <div className="relative grid gap-8 bg-white p-7 dark:bg-neutral-800">
                                    {flightClass.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setFlightClassState(item.name);
                                                close();
                                            }}
                                            className="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 dark:hover:bg-gray-700"
                                        >
                                            <p className="text-sm font-medium">{item.name}</p>
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </>
            )}
        </Popover>
    );

    const options = [
        { place: 'restaurant', name: 'Restaurant', Icon: LunchDiningOutlined },
        { place: 'bar', name: 'Club, Bar', Icon: LocalBarOutlined },
        { place: 'cafe', name: 'Caffe', Icon: CoffeeOutlined },
        // { place: 'gym', name: 'Fitness', Icon: FitnessCenterOutlined },
        { place: 'night_club', name: 'Night club', Icon: FavoriteBorderOutlined },
        { place: 'park', name: 'Park', Icon: ParkOutlined },
        { place: 'spa', name: 'Wellness', Icon: SelfImprovementOutlined },
    ];
    const renderOption = ({ place, name, Icon }: { place: string; name: string; Icon: SvgIconComponent }) => (
        <div
            key={place}
            className={`my-1 mr-2 flex cursor-pointer items-center rounded-full px-4 py-1.5 text-xs font-medium sm:mr-3 ${
                selectedProperty === place
                    ? 'bg-black text-white shadow-lg shadow-black/10'
                    : 'border border-neutral-300 dark:border-neutral-700'
            }`}
            onClick={() => {
                setSelectedProperty(place);
            }}
        >
            {Icon && <Icon className="mr-2 h-4 w-4" />}
            {name}
        </div>
    );

    const renderRadioBtn = () => (
        <div className="flex items-center justify-around">
            <div className="[ nc-hero-field-padding ] flex flex-row flex-wrap justify-center border-b border-neutral-100 py-5 dark:border-neutral-700">
                {options.map((item) => renderOption(item))}
            </div>
            {state.hasLocationError ? (
                <div className="hidden p-4 md:grid">
                    <ButtonSubmit />
                </div>
            ) : null}
        </div>
    );

    const renderForm = () => (
        <form className="relative mt-8 w-full rounded-[40px] rounded-t-2xl bg-white shadow-xl xl:rounded-[49px] xl:rounded-t-3xl dark:bg-neutral-800 dark:shadow-2xl">
            {renderRadioBtn()}
            <div className="flex flex-1 rounded-full">
                {/* <LocationInput placeHolder="From" desc="Current location..." className="flex-1" /> */}
                {/* <div className="h-8 self-center border-r border-slate-200 dark:border-slate-700" /> */}
                {/* <LocationInput */}
                {/*    placeHolder="Flying to" */}
                {/*    desc="Where you want to fly to?" */}
                {/*    className="hidden flex-1 lg:block" */}
                {/*    divHideVerticalLineClass=" -inset-x-0.5" */}
                {/* /> */}
                {/* <div className="h-8 self-center border-r border-slate-200 dark:border-slate-700" /> */}
                <FlightDateRangeInput className="flex-1" />
            </div>
            <div className="flex flex-1 rounded-full p-3 md:hidden">
                <ButtonSubmit />
            </div>
        </form>
    );

    return renderForm();
};
