'use client';

import React, { FC } from 'react';

import { FlightSearchForm } from './FlightSearchForm/FlightSearchForm';

export interface HeroSearchFormProps {
    className?: string;
}

export const HeroSearchForm: FC<HeroSearchFormProps> = ({ className = '' }) => (
    <div className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}>
        <FlightSearchForm />
    </div>
);
