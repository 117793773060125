import React, { FC } from 'react';

import { useGlobalContext } from '@/hooks/useGlobalContext';

interface Props {
    className?: string;
}

const ButtonSubmit: FC<Props> = ({ className }) => {
    const {
        actions: { onPrimaryButtonClick },
    } = useGlobalContext();
    return (
        <div
            onClick={onPrimaryButtonClick}
            className={`flex h-14 w-full cursor-pointer items-center justify-center rounded-full bg-primary-6000 text-neutral-50 hover:bg-primary-700 focus:outline-none md:h-16 md:w-16 ${className}`}
        >
            <span className="mr-3 md:hidden">Search</span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
            </svg>
        </div>
    );
};

export default ButtonSubmit;
