'use client';

import { Popover } from '@headlessui/react';
import { Slider, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';

import { MapBoxMap } from '@/app/components/LocationMap/MapBoxMap';
import ButtonSecondary from '@/app/components/shared/Button/ButtonSecondary';
import { useGlobalContext } from '@/hooks/useGlobalContext';
import { useLocationStore } from '@/store/useStore';
import ButtonSubmit from '../ButtonSubmit';

export interface FlightDateRangeInputProps {
    className?: string;
    hasButtonSubmit?: boolean;
}

const FlightDateRangeInput: FC<FlightDateRangeInputProps> = ({ className = '', hasButtonSubmit = true }) => {
    const context = useGlobalContext();

    const coordinates = useLocationStore((state) => state.coordinates);
    const { setSelectedDistance } = context.actions;
    const { selectedDistance, state } = context;
    const changeCustomLocation = () => {
        context.dispatch({ type: 'setLocationError', payload: true }); // TODO: change to false
    };
    const renderInput = () => (
        <div className="flex items-center justify-center text-center">
            <div className="flex-grow text-center">
                <span className="mt-2 block font-semibold xl:text-lg">Distance</span>
                <Stack direction="column" justifyContent="center" alignItems="center">
                    <Slider
                        size="medium"
                        defaultValue={selectedDistance / 1000}
                        value={selectedDistance / 1000}
                        aria-label="Default"
                        max={2.5}
                        min={0.2}
                        step={0.2}
                        valueLabelFormat={(val) => `${val * 1000}m ~${Math.floor((val * 1000) / 83.333)} min`}
                        valueLabelDisplay="auto"
                        color="secondary"
                        // marks={[
                        //     { value: 0.2, label: '2 min' },
                        //     { value: 2.5, label: '30 min' },
                        // ]}
                        onChange={(_e, value) => {
                            if (typeof value === 'number') {
                                setSelectedDistance(value * 1000);
                            }
                        }}
                    />
                    <Typography variant="caption">
                        <Stack direction="row" justifyContent="center" spacing="10px" alignItems="center" mb="20px">
                            {`${selectedDistance} meters`}
                            {` ~${Math.floor(selectedDistance / 83.333)} min`}
                        </Stack>
                    </Typography>
                </Stack>
            </div>
            {state.hasCustomLocation ? (
                <div className="ml-4 flex-grow">
                    <ButtonSecondary className="align-middle" fontSize="20px" onClick={changeCustomLocation}>
                        Change my location
                    </ButtonSecondary>
                </div>
            ) : null}
        </div>
    );

    return (
        <Popover className={`FlightDateRangeInput relative flex ${className} flex w-full flex-row flex-wrap-reverse`}>
            {({ open }) => (
                <>
                    {state.hasLocationError ? (
                        <>
                            <div className="flex w-full flex-col text-center">
                                <div className="text-center text-xs font-light text-red-600">
                                    Your location service are disabled.
                                </div>
                                <div className="text-center font-light">Select your approximate location on map</div>
                                <MapBoxMap
                                    // TODO fallback to something COOL
                                    latitude={coordinates?.latitude || 50.015685}
                                    longitude={coordinates?.longitude || 14.435611}
                                />
                            </div>
                        </>
                    ) : (
                        <div className="pl-10">{renderInput()}</div>
                    )}

                    {/* BUTTON SUBMIT OF FORM */}
                    {!state.hasLocationError && !state.isLocationLoading && (
                        <div className={'z-10 ml-auto flex items-center justify-between focus:outline-none'}>
                            <div className="hidden pr-2 md:block xl:pr-4">
                                <ButtonSubmit />
                            </div>
                        </div>
                    )}
                </>
            )}
        </Popover>
    );
};

export default FlightDateRangeInput;
