'use client';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import { MainPage } from '@/app/components/MainPage';
import { WithProviders } from '@/app/components/WithProviders';
import { getServerUrl } from '@/app/utils';

const queryClient = new QueryClient();

const getGraphQLUri = () => `${getServerUrl()}/graphql`;
const client = new ApolloClient({
    uri: getGraphQLUri(),
    cache: new InMemoryCache(),
});

export default function App() {
    return (
        <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
                <WithProviders>
                    <MainPage />
                </WithProviders>
            </QueryClientProvider>
        </ApolloProvider>
    );
}
