import { create } from 'zustand';

interface State {
    photoToScrollTo: null | string;
    setPhotoToScrollTo: (photo: string | null) => void;
}
export const usePhotoStore = create<State>()((set) => ({
    photoToScrollTo: null,
    setPhotoToScrollTo: (photo) => set({ photoToScrollTo: photo }),
}));
